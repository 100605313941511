import styled from '@emotion/styled/macro';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  & .input {
    background: rgba(28, 31, 33, 0.9);
    border-radius: 8px;
    font-weight: normal;
    font-size: 17px;
    line-height: 22px;
    color: #fff;
    border: none;
    padding: 17px 17px 17px 50px;
    width: 100%;
    z-index: 17;

    &::placeholder {
      color: #7f8489;
    }
  }

  & .icon {
    position: absolute;
    left: 4%;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #5F6975;
    z-index: 18;
  }
`;


export default { Wrapper }