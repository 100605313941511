import React, { FC } from 'react';
import { SearchTextInputWithDebounce } from 'components/fields/searchTextInputWithDebounce/SearchTextInputWithDebounce';
import gaEvents from 'utils/gaEvents';

import S from './Search.styled';

interface ISearchProps {
  searchValue: string;
  filterClientsHandler: (searchValue: string) => void;
}

const SEARCH_STYLES = {
  border: "0.5px solid #3e434b",
  padding: "4px 4px 4px 30px",
  fontSize: 14,
  borderRadius: 4,
};

export const Search: FC<ISearchProps> = ({
  searchValue,
  filterClientsHandler,
}) => {
  const onFocus = () => {
    gaEvents.chatFocusOnSearchField();
  }

  return (
    <S.Wrapper>
      <SearchTextInputWithDebounce
        onChange={filterClientsHandler}
        onFocus={onFocus}
        debounceTime={500}
        value={searchValue}
        styles={SEARCH_STYLES}
      />
    </S.Wrapper>
  );
};
