import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  
  flex: 1;
  padding: 12px;
  border-top: 1px solid #6e7987;
  
  & .inputWrapper {
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  & .exerciseReferenceWrapper {
    display: flex;
    margin-top: 16px;
  }
`;

const AttachButton = styled.button`
  display: flex;
  align-items: center;

  padding: 6px;
  color: #6e7987;
`;

const Input = styled.input`
  flex: 1;

  border: none;
  border-radius: 5px;
  background: transparent;
  outline: none;
  color: #fff;

  font-size: 16px;
`;

const SendButton = styled.button<{
  disabled?: boolean;
}>`
  background-color: transparent;
  color: #ffef01;

  padding: 2px 6px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      color: #6e7987;
    `}
`;

export default { Container, AttachButton, Input, SendButton };
