import React, { CSSProperties, FC } from 'react';
import { isChatPage } from 'containers/app/trainer/currentClient/clientPhases/utils';
import { Tooltip } from 'components/tooltip/Tooltip';
import { CHAT } from 'constants/tooltips';
import { QuickMessage } from '../quickMessage/QuickMessage';

import type { TGeneralExerciseReference } from '../../models';

import { ChatIc } from 'assets/svg';
import S from './ChatIcon.styled';

interface IChatIconProps {
  generalExerciseReference?: TGeneralExerciseReference;
  isGeneratingReferenceDisabled: boolean;
  generateReferenceHandler: () => void;
  onShowPopoverCallback: (isShown: boolean) => void;
  styles?: CSSProperties;
}

export const ChatIcon: FC<IChatIconProps> = ({
  generalExerciseReference,
  isGeneratingReferenceDisabled,
  generateReferenceHandler,
  onShowPopoverCallback,
  styles = {},
}) => {
  if (!generalExerciseReference) {
    return null;
  }
  return (
    <S.Wrapper style={styles}>
      {isChatPage() ? (
        <Tooltip
          title={
            isGeneratingReferenceDisabled
              ? CHAT.GENERATE_EXERCISE_REFERENCE_DISABLED
              : CHAT.GENERATE_EXERCISE_REFERENCE
          }
        >
          <button
            className="chatButton"
            onClick={generateReferenceHandler}
            disabled={isGeneratingReferenceDisabled}
          >
            <ChatIc className="chatIcon" />
          </button>
        </Tooltip>
      ) : (
        <QuickMessage
          generalExerciseReference={generalExerciseReference}
          onShowPopoverCallback={onShowPopoverCallback}
        />
      )}
    </S.Wrapper>
  );
};
