import React, { FC } from 'react';
import gaEvents from 'utils/gaEvents';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';

import { CloseIc } from 'assets/svg';
import S from './ExerciseReference.styled';

interface IExerciseReferenceProps {
  exerciseReference: TGeneralExerciseReference;
  readOnly?: boolean;
  isGeneratedReferenceActive?: boolean;
  setIsGeneratedReferenceActive?: (active: boolean) => void;
}

export const ExerciseReference: FC<IExerciseReferenceProps> = ({
  exerciseReference: {
    phaseName = '',
    dayName = '',
    exerciseName = '',
    complexExerciseName = '',
  },
  readOnly = false,
  isGeneratedReferenceActive = true,
  setIsGeneratedReferenceActive,
}) => {
  const generateExerciseReference = () =>
    `${phaseName} | ${dayName} | ${
      // "|| complexExerciseName" - is added to support old messages which have
      // attached previous built form for reference, where last point was - complexExerciseName
      exerciseName || complexExerciseName
    }`;

  const activateGeneratedReference = () => {
    gaEvents.chatActivateExerciseReference();
    setIsGeneratedReferenceActive?.(true);
  }

  const deactivateGeneratedReference = () => {
    gaEvents.chatDeactivateExerciseReference();
    setIsGeneratedReferenceActive?.(false);
  }

  return (
    <S.Wrapper isReferenceActive={isGeneratedReferenceActive}>
      <p className="message">{generateExerciseReference()}</p>
      {!readOnly && isGeneratedReferenceActive ? (
        <button
          onClick={deactivateGeneratedReference}
          className="deleteButton"
        >
          <CloseIc />
        </button>
      ) : !isGeneratedReferenceActive ? (
        <button
          onClick={activateGeneratedReference}
          className="plusButton"
        >
          <CloseIc />
        </button>
      ) : null}
    </S.Wrapper>
  );
};
