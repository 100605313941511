import styled from '@emotion/styled';
import { css } from '@emotion/react';

const Wrapper = styled.div<{
  isReferenceActive: boolean
}>`
  display: flex;
  align-items: center;
  align-self: flex-start;
  
  background-color: #FFEF01;
  color: #5F6975;
  border-radius: 4px;
  padding: 4px 4px 4px 8px;
  
  ${({ isReferenceActive }) => !isReferenceActive && css`
    background: #6E7987;
    cursor: default;
  `}

  & .message {
    color: #1C1F21;
    
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding-right: 4px;
  }
  
  & .deleteButton, & .plusButton {
    display: flex;
    align-items: center;
  }
  
  & .plusButton {
    transform: rotate(45deg);
    color: #FFEF01!important;
  }
`;

export default { Wrapper }