import { useSnackbar } from 'notistack';
import { useMutation } from 'react-query';
import instance from 'lib/api/axios';
import { ERROR_MESSAGE } from 'constants/index';
import {
  CLIENT_MESSAGES_LIMIT,
  CLIENTS_ON_PAGE_LIMIT,
  getRoundedArrayByLimit,
} from '../../utils';

import type {
  TClientWithMessages,
  TGetClientMessagesPayload,
  TGetClientsPayload,
  TMessage,
} from '../../models';

type TUseFetch = {
  clients: TClientWithMessages[] | null;
  setClients: (clients: TClientWithMessages[]) => void;
  openCurrentClientChat: (clients: TMessage[]) => void;
  activeMessages: TMessage[];
};

type TGetClientsResponseData = {
  data: {
    items: TClientWithMessages[];
  };
  page: number;
};

type TGetClientMessagesResponseData = {
  data: {
    items: TMessage[];
  };
  doNotConcatMessages: boolean;
  limit: number;
  prevMessages?: TMessage[];
};

export const useFetch = ({
  clients,
  setClients,
  openCurrentClientChat,
  activeMessages,
}: TUseFetch) => {
  const { enqueueSnackbar } = useSnackbar();

  const { mutate: getClientsMutation, isLoading: isLoadingClientsList } =
    useMutation<TGetClientsResponseData, Error, TGetClientsPayload>({
      mutationFn: async ({ page = 1, fullName = '' }) => {
        const { data } = await instance.get(
          `Trainer/clients-with-messages?page=${page}&limit=${CLIENTS_ON_PAGE_LIMIT}&fullName=${fullName}`,
        );
        return { data, page };
      },
      onSuccess: ({ data: { items }, page }) => {
        setClients(clients && page !== 1 ? clients.concat(items) : items);
      },
      onError: (e) => {
        const error = String(e) || ERROR_MESSAGE;
        enqueueSnackbar(error, { variant: 'error' });
      },
    });

  const {
    mutate: getClientMessagesMutation,
    isLoading: isLoadingClientMessages,
  } = useMutation<
    TGetClientMessagesResponseData,
    Error,
    TGetClientMessagesPayload
  >({
    mutationFn: async ({
      clientId,
      page = 1,
      limit = CLIENT_MESSAGES_LIMIT,
      doNotConcatMessages = false,
      prevMessages,
    }) => {
      const { data } = await instance.get(
        `GeneralMessage?clientId=${clientId}&page=${page}&limit=${limit}`,
      );
      return { data, doNotConcatMessages, limit, prevMessages };
    },
    onSuccess: ({
      data: { items },
      doNotConcatMessages,
      limit,
      prevMessages,
    }) => {
      if (doNotConcatMessages) {
        openCurrentClientChat(items);
        // to handle pushing only one element after deleting message
      } else if (limit === 1) {
        const messages = prevMessages || activeMessages;
        openCurrentClientChat(items.concat(messages));
      } else {
        // if for previous ${CLIENT_MESSAGES_LIMIT} count - there was posted some more messages - need to
        // calculate rounded activeMessages for correct pagination working
        const roundedMessagesByLimitCount = getRoundedArrayByLimit(
          activeMessages,
          CLIENT_MESSAGES_LIMIT,
        );

        openCurrentClientChat(
          roundedMessagesByLimitCount
            ? items.concat(roundedMessagesByLimitCount)
            : items,
        );
      }
    },
    onError: (e) => {
      const error = String(e) || ERROR_MESSAGE;
      enqueueSnackbar(error, { variant: 'error' });
    },
  });

  return {
    getClientsMutation,
    isLoadingClientsList,

    getClientMessagesMutation,
    isLoadingClientMessages,
  };
};
