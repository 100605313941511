import React, { FC } from 'react';
import { renderAttachmentByFileType } from '../../utils';
import { fileType } from '../../../../clientsList/client/Client';

import type { TMessage } from '../../../../models';

import { CloseIc, ReplyIc } from 'assets/svg';
import S from './ReplyPreview.styled';

interface IReplyPreview {
  message: TMessage;
  onSetReplyMessage: (arg: null) => void;
}

export const ReplyPreview: FC<IReplyPreview> = ({
  message: { attachmentUrl, attachmentThumbnailUrl, text, fileType: messageFileType},
  onSetReplyMessage,
}) => {
  return (
    <S.Wrapper>
      <div>
        <ReplyIc />
      </div>
      {attachmentUrl &&
        renderAttachmentByFileType({
          attachmentUrl,
          attachmentThumbnailUrl,
          fileType: messageFileType,
          isForPreview: true,
        })}
      <div className="textWrapper">
        <p className="text">{text}</p>
        {attachmentUrl && typeof messageFileType === 'number' && (
            <p className="attachmentLabel">{fileType[messageFileType].name}</p>
          )}
      </div>
      <button className="close" onClick={() => onSetReplyMessage(null)}>
        <CloseIc />
      </button>
    </S.Wrapper>
  );
};
