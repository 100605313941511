import styled from '@emotion/styled/macro';
import {
  flexCenteredStyles,
  renderTextWithThreePointsByRowsCount,
} from 'styles/commonStyleVariables';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;

  background: #1c1f21;
  padding: 8px 16px;
  border-top: 0.5px solid #5f6875;
  color: #5f6975;
  overflow: hidden;

  & .text {
    margin-right: auto;
    color: #fff;
    font-size: 16px;
    line-height: 20px;

    ${renderTextWithThreePointsByRowsCount(2)};
  }

  & .videoWrapper, .imageWrapper {
    ${flexCenteredStyles};
    height: 50px;
    width: 50px;
    
    & .image {
      width: 100%;
      height: 100%;
    }
  }

  & .close {
    margin-left: auto;
  }
  
  & .textWrapper {
    flex: 1;
  }
`;

export default { Wrapper }