export const flexCenteredStyles = `
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ellipsisTextStyles = `
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  white-space: nowrap;
  cursor: default;
`;

export const transitionOnIconsHover = `
    transition: all 0.2s;
`

export const renderTextWithThreePointsByRowsCount = (rowsCount = 1) => `
    display: -webkit-box;
    -webkit-line-clamp: ${rowsCount};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`

