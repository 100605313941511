import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import gaEvents from 'utils/gaEvents';
import { ExerciseReference } from '../exerciseReference/ExerciseReference';
import { useInputFocus } from 'hooks/useInputFocus';
import { noop } from 'utils';

import type { TGeneralExerciseReference } from 'components/_new/phase/models';
import type { IMessageProps } from '../../chat/message/models';
import type { TMessage } from '../../models';

import S from './MessageInput.styled';

interface IMessageInputProps {
  onSendMessage: IMessageProps['onSendMessage'];
  updateMessagesOnSuccessSend?: (newMessages: TMessage[]) => void;
  generatedExerciseReference: TGeneralExerciseReference | null;
  setGeneratedExerciseReference: (
    reference: TGeneralExerciseReference | null,
  ) => void;
  isGeneratedReferenceActive: boolean;
  setIsGeneratedReferenceActive: (active: boolean) => void;
  isLastMessageSending?: boolean;
  isGeneratingReferenceDisabled: boolean;
}

export const MessageInput: FC<IMessageInputProps> = ({
  onSendMessage,
  generatedExerciseReference,
  setGeneratedExerciseReference,
  isGeneratedReferenceActive,
  setIsGeneratedReferenceActive,
  isLastMessageSending,
  isGeneratingReferenceDisabled,
  updateMessagesOnSuccessSend = noop,
}) => {
  const inputRef = useInputFocus();
  const [message, setMessage] = useState('');

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };

  // TODO: add also check for attached file after it will be implemented
  const isSendDisabled = !message || isLastMessageSending;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isSendDisabled) {
      gaEvents.chatSendMessageOnEnter();
      handleSend();
    }
  };
  const handleSend = () => {
    const trimmedMessage = message.trim();
    if (trimmedMessage || generatedExerciseReference) {
      onSendMessage({
        text: trimmedMessage,
        updateCallback: updateMessagesOnSuccessSend,
      });
      setGeneratedExerciseReference(null);
      setMessage('');
    }
  };

  const handleSendButtonClick = () => {
    gaEvents.chatClickSendMessageButton();
    handleSend();
  };

  const handleFocus = () => {
    gaEvents.chatFocusOnTypeMessageInput();
  };

  return (
    <S.Container>
      <div className="inputWrapper">
        {/*Disabled for first iteration. @TODO - implement*/}
        {/*<S.AttachButton>*/}
        {/*  <AttachIc />*/}
        {/*</S.AttachButton>*/}
        <S.Input
          type="text"
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          placeholder="Type message..."
          ref={inputRef}
        />
        <S.SendButton disabled={isSendDisabled} onClick={handleSendButtonClick}>
          Send
        </S.SendButton>
      </div>
      {generatedExerciseReference && (
        <div className="exerciseReferenceWrapper">
          <ExerciseReference
            exerciseReference={generatedExerciseReference}
            isGeneratedReferenceActive={isGeneratedReferenceActive}
            setIsGeneratedReferenceActive={setIsGeneratedReferenceActive}
            readOnly={isGeneratingReferenceDisabled}
          />
        </div>
      )}
    </S.Container>
  );
};
