import React, {
  createContext,
  FC,
  MutableRefObject,
  ReactNode,
  useRef,
} from 'react';

interface IFocusContextProps {
  inputRef: MutableRefObject<HTMLInputElement | null>;
}

export const InputFocusContext = createContext<IFocusContextProps | undefined>(
  undefined,
);

export const InputFocusProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <InputFocusContext.Provider value={{ inputRef }}>
      {children}
    </InputFocusContext.Provider>
  );
};
