export const HEADER = {
  SEND_FEEDBACK: 'Send Feedback',
  ONBOARDING: 'Onboarding',
  NOTIFICATIONS: 'Notifications',
  EDIT_PHOTO_PROFILE: 'Change Profile Picture',
};

export const SIDEBAR = {
  COLLAPSE_MENU: 'Collapse menu',
  EXPAND_MENU: 'Expand menu',
  DOCUMENTATION_GUIDE: 'Documentation Guide',
};

export const CLIENT = {
  SAVE_NOTES: 'Save Notes',
};

export const PROGRAM = {
  DELETE_PROGRAM: 'Delete Program',
  LOCK_PHASE: 'Lock Phase',
  UNLOCK_PHASE: 'Unlock Phase',
  DELETE_PHASE: 'Delete Phase',
  DUPLICATE_PHASE: 'Duplicate Phase',
  COLLAPSE_PHASE: 'Collapse Phase',
  EXPAND_PHASE: 'Expand Phase',
  SAVE_AS_TEMPLATE: 'Save As Template',
  BP_VIEW: 'Bigger Picture View',
  DBD_VIEW: 'Day By Day View',
  COLLAPSED_VIEW: 'Collapsed View',
  DUPLICATE_DAY: 'Duplicate Day',
  DELETE_DAY: 'Delete Day',
  EDIT_SINGLE_EXERCISE: 'Edit Exercise',
  DELETE_SINGLE_EXERCISE: 'Delete Exercise',
  EDIT_COMPLEX_EXERCISES: 'Edit Superset',
  DELETE_COMPLEX_EXERCISES: 'Delete Superset',
  QUICK_MESSAGE: 'Attach exercise reference to message',
  EDIT_PROGRAM: 'Edit workout name',
  MOVE_EXERCISE_UP: 'Move Exercise Up',
  MOVE_EXERCISE_DOWN: 'Move Exercise Down',
  FILTER_BUTTON: 'Add a message filter for this exercise',
  RPE_SELECTOR_INFO: 'Enter a value from 0 to 10 that is a multiple of 0.5',
};

export const EXERCISES = {
  EDIT_EXERCISE: 'Edit Exercise',
  DELETE_EXERCISE: 'Delete Exercise',
  DELETE_VIDEO: 'Delete Video',
};

export const TEMPLATES = {
  DELETE_TEMPLATE: 'Delete Template',
};

export const CHAT = {
  GO_BACK: 'Back to list of chats',
  GENERATE_EXERCISE_REFERENCE: 'Attach exercise reference to message',
  GENERATE_EXERCISE_REFERENCE_DISABLED:
    'You can\'t generate an exercise reference when the replied message already contains one',
  OPEN_USER_PROFILE: 'Open user profile',
  CLEAR_FILTER: 'Clear Filter',
};
