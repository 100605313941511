import React, { FC, useContext, useEffect, useRef, useState } from 'react';

import { MessageInput } from 'pages/ChatPage/components/messageInput/MessageInput';
import { useFetch } from './hooks/useFetch';
import { Message } from './message/Message';
import { InfiniteScrollListWrapper } from '../components/infiniteScrollListWrapper/InfiniteScrollListWrapper';
import { CLIENT_MESSAGES_LIMIT, getRoundedArrayByLimit } from '../utils';
import {
  ExerciseReferenceContext,
  IS_GENERATED_REFERENCE_ACTIVE_DEFAULT_VALUE,
  IS_GENERATING_REFERENCE_DISABLED_DEFAULT_VALUE,
} from 'providers/chatPage/ExerciseReferenceProvider';
import { UserContext } from 'providers/userProvider';
import { Tooltip } from 'components/tooltip/Tooltip';
import { CHAT } from 'constants/tooltips';
import { FilteredExerciseContext } from 'providers/chatPage/filteredExerciseProvider/FilteredExerciseProvider';
import { ColoredLabel } from 'components/coloredLabel/ColoredLabel';
import Loader from 'components/loader/Loader';
import { ReplyPreview } from './message/components/replyPreview/ReplyPreview';
import { useInputFocus } from '../../../hooks/useInputFocus';
import { ChatHeader } from './components/chatHeader/ChatHeader';
import {
  checkUserDataToBeShown,
  updateMessageReactionsById,
} from './message/utils';
import { DEFAULT_NEW_MESSAGE } from './message/constants';

import type {
  TClientWithMessages,
  TGetClientMessagesPayload,
  TGetClientsPayload,
  TMessage,
} from '../models';
import type { IMessageProps, TReactOnMessageResponse } from './message/models';
import type { TSendMessagePayload } from './hooks/useFetch';

import { CloseIc } from 'assets/svg';
import S from './Chat.styled';

export const SCROLLABLE_MESSAGES_LIST_ID = 'scrollableMessagesListId';

interface IChat {
  activeClient: TClientWithMessages;
  activeMessages: TMessage[];
  setActiveMessages: React.Dispatch<React.SetStateAction<TMessage[]>>;
  backToClientsListHandler: () => void;
  getClientsMutation: (payload: TGetClientsPayload) => void;
  getClientMessagesMutation: (data: TGetClientMessagesPayload) => void;
  resizeChatHandler: () => void;
}

export const Chat: FC<IChat> = ({
  activeClient,
  activeMessages,
  setActiveMessages,
  backToClientsListHandler,
  getClientsMutation,
  getClientMessagesMutation,
  resizeChatHandler,
}) => {
  const [replyMessage, setReplyMessage] = useState<TMessage | null>(null);
  const { user: currentUser, setUser } = useContext(UserContext);
  const {
    setNavigatedExerciseReference,
    generatedExerciseReference,
    setGeneratedExerciseReference,
    isGeneratedReferenceActive,
    setIsGeneratedReferenceActive,
    isGeneratingReferenceDisabled,
    setIsGeneratingReferenceDisabled,
  } = useContext(ExerciseReferenceContext);
  const { user } = activeClient;

  const {
    filteredExercise,
    setFilteredExercise,
    getMessagesByExerciseHandler,
    isLoadingGetMessagesByExercise,
  } = useContext(FilteredExerciseContext);

  useEffect(() => {
    if (filteredExercise?.programId) {
      scrollToBottom();
      getMessagesByExerciseHandler({
        callbackOnSuccess: setActiveMessages,
      });
    } else {
      getClientMessagesMutation({
        clientId: activeClient?.id,
        doNotConcatMessages: true,
      });
    }
  }, [filteredExercise]);

  useEffect(() => {
    // clear all filters and exercise reference on unmount
    return () => {
      setFilteredExercise(null);
      setGeneratedExerciseReference(null);
      setIsGeneratingReferenceDisabled(
        IS_GENERATING_REFERENCE_DISABLED_DEFAULT_VALUE,
      );
      setIsGeneratedReferenceActive(
        IS_GENERATED_REFERENCE_ACTIVE_DEFAULT_VALUE,
      );
    };
  }, []);

  const roundedMessagesByLimitCount = getRoundedArrayByLimit(activeMessages);
  const activePage = Math.ceil(
    (roundedMessagesByLimitCount?.length || CLIENT_MESSAGES_LIMIT) /
      CLIENT_MESSAGES_LIMIT,
  );

  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // read all messages when user visits chat page with certain client
    readAllMessagesMutation({
      userId: activeClient.id,
    });
    return () => {
      // delete and activate exerciseReference from input after going out from chat
      setIsGeneratedReferenceActive(true);
      setGeneratedExerciseReference(null);
    };
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [activeClient]);

  const updateMessagesOnSuccessSend = (newMessages: TMessage[], withScrolling = true) => {
    setActiveMessages((prevMessages) => {
      // replace mockedNewMessage to new from back end
      const updatedMessages = prevMessages?.slice(0, -1) || [];
      return [...updatedMessages, ...newMessages];
    });

    if (withScrolling) {
      scrollToBottom();
    }
  };

  const markAllMessagesAsReadOnSuccess = () => {
    // update chat counter in sidebar
    // (activeClient.totalUnreadMessages - if totalUnreadMessages > 0 for this user)
    if (currentUser?.totalUnreadMessages && activeClient.totalUnreadMessages) {
      const totalUnreadMessages =
        currentUser.totalUnreadMessages - activeClient.totalUnreadMessages;
      setUser({
        ...currentUser,
        totalUnreadMessages: totalUnreadMessages || 0,
      });
    }

    getClientsMutation({});
  };

  const {
    sendMessageMutation,
    readAllMessagesMutation,
  } = useFetch({
    markAllMessagesAsReadOnSuccess,
    updateMessagesOnSuccessSend,
  });

  const inputRef = useInputFocus();
  const onSetReplyMessage = (message: TMessage | null) => {
    setReplyMessage(message);
    if (message?.exerciseReference) {
      if (!isGeneratedReferenceActive) {
        // exerciseRef shouldn't be disabled in reply mode
        setIsGeneratedReferenceActive(true);
      }
      // disable creating exerciseReference(ER) on BP if there is ER in reply message
      setIsGeneratingReferenceDisabled(true);
      // generate reference from replied in "type message" input - for current new message
      setGeneratedExerciseReference(message.exerciseReference);
    } else if (isGeneratingReferenceDisabled) {
      // enable creating exerciseReference(ER) on BP if there ISN'T ER in reply message
      setIsGeneratingReferenceDisabled(false);
    }

    // to focus on Input ("Type message...") in chat section
    // after reply button on message is clicked
    inputRef?.current?.focus();
  };

  const onSendMessage: IMessageProps['onSendMessage'] = ({
    text,
    repliedMessage,
    exerciseReference,
    updateCallback,
    shouldScrollToTheBottom = true,
  }) => {
    const replied = repliedMessage || replyMessage;

    const messagePayload: TSendMessagePayload['messagePayload'] = {
      receiverIds: [activeClient.id],
      text,
      repliedId: repliedMessage?.id || replyMessage?.id,
      replied,
    };
    const mockedNewMessage: TMessage = {
      ...DEFAULT_NEW_MESSAGE,
      receiverId: activeClient.id,
      senderId: currentUser?.id || '',
      text,
      replied,
    };

    if (exerciseReference) {
      // to add exerciseReference to message if it is message from media modal (LightboxModalMediaMessages)
      messagePayload.exerciseReference = exerciseReference;
      mockedNewMessage.exerciseReference = exerciseReference;
    } else if (generatedExerciseReference && isGeneratedReferenceActive) {
      messagePayload.exerciseReference = generatedExerciseReference;
      mockedNewMessage.exerciseReference = generatedExerciseReference;
    }
    setActiveMessages([...activeMessages, mockedNewMessage]);
    if (shouldScrollToTheBottom) {
      scrollToBottom();
    }
    sendMessageMutation({ messagePayload, updateCallback });
    onSetReplyMessage(null);
  };

  const fetchMoreMessagesHandler = () => {
    getClientMessagesMutation({
      clientId: activeClient?.id,
      page: activePage + 1,
    });
  };

  const onCloseExerciseFilter = () => {
    scrollToBottom();
    setFilteredExercise(null);
    getClientMessagesMutation({
      clientId: activeClient?.id,
      doNotConcatMessages: true,
    });
  };

  const onSuccessDeleteMessage = (messageId: string) => {
    setActiveMessages((prevMessages) => {
      const updatedMessages = prevMessages?.filter(
        (message) => message.id !== messageId,
      );

      if (!filteredExercise) {
        // get and push one more (+1) message after one old was deleted
        // if there is no filter by exercise
        getClientMessagesMutation({
          clientId: activeClient?.id,
          page: (updatedMessages?.length || 0) + 1,
          limit: 1,
          prevMessages: updatedMessages,
        });
      }

      return updatedMessages || null;
    });
  };

  // optimistic scenario - update reactions field for clicked message while
  // request is running
  const onSuccessAddReaction = ({
    reaction,
    messageId,
  }: TReactOnMessageResponse) => {
    setActiveMessages((prevMessages) => {
      return updateMessageReactionsById({
        reaction,
        messageId,
        prevMessages,
        currentUserId: currentUser?.id,
      });
    });
  };

  const onReply = (message: TMessage) => {
    onSetReplyMessage(message);
  };

  return (
    <S.Wrapper isFilterActive={Boolean(filteredExercise)}>
      {isLoadingGetMessagesByExercise && <Loader relatedToParent />}
      <ChatHeader
        backToClientsListHandler={backToClientsListHandler}
        user={user}
        expandHandler={resizeChatHandler}
      />
      {filteredExercise && (
        <div className="exercise">
          <ColoredLabel index={filteredExercise.complexExerciseIndex}>
            {filteredExercise.letter + (filteredExercise.orderNumber + 1)}
          </ColoredLabel>
          <p className="exerciseName">{filteredExercise.exerciseInfo.name}</p>
          <Tooltip title={CHAT.CLEAR_FILTER}>
            <button className="closeExercise" onClick={onCloseExerciseFilter}>
              <CloseIc />
            </button>
          </Tooltip>
        </div>
      )}
      <div className="messages" id={SCROLLABLE_MESSAGES_LIST_ID}>
        <InfiniteScrollListWrapper
          dataLength={activeMessages.length}
          fetchMoreMessagesHandler={fetchMoreMessagesHandler}
          inverse
          scrollableListId={SCROLLABLE_MESSAGES_LIST_ID}
          hasMore={!filteredExercise}
        >
          <>
            {activeMessages.map((messageData, index) => {
              const isUserDataShown = checkUserDataToBeShown({
                messageData,
                index,
                activeMessages,
              });

              return (
                <Message
                  key={messageData.id}
                  messageData={messageData}
                  isUserDataShown={isUserDataShown}
                  currentUserId={currentUser?.id}
                  setNavigatedExerciseReference={setNavigatedExerciseReference}
                  onSuccessDeleteMessage={onSuccessDeleteMessage}
                  onSuccessAddReaction={onSuccessAddReaction}
                  onReply={() => onReply(messageData)}
                  activeClient={activeClient}
                  getClientsMutation={getClientsMutation}
                  getClientMessagesMutation={getClientMessagesMutation}
                  user={user}
                  onSendMessage={onSendMessage}
                />
              );
            })}
            <div ref={messagesEndRef} />
          </>
        </InfiniteScrollListWrapper>
      </div>
      {replyMessage && (
        <ReplyPreview
          message={replyMessage}
          onSetReplyMessage={onSetReplyMessage}
        />
      )}
      <MessageInput
        onSendMessage={onSendMessage}
        updateMessagesOnSuccessSend={updateMessagesOnSuccessSend}
        generatedExerciseReference={generatedExerciseReference}
        setGeneratedExerciseReference={setGeneratedExerciseReference}
        isGeneratedReferenceActive={isGeneratedReferenceActive}
        setIsGeneratedReferenceActive={setIsGeneratedReferenceActive}
        isLastMessageSending={
          activeMessages[activeMessages.length - 1]?.isSending
        }
        isGeneratingReferenceDisabled={isGeneratingReferenceDisabled}
      />
    </S.Wrapper>
  );
};
